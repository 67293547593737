import jQuery from 'jquery';
import Cookies from "js-cookie";

const cookieName = 'disclaimer-notice';
const linkTarget = '/datenschutz';

function disclaimerElement(target) {
    const nodeString = [
        '<section class="disclaimer-info"><article class="disclaimer-info-article"><p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer <a class="disclaimer-info-link j-open-modal" href="',target,'">Datenschutzerklärung</a></p></article><aside class="disclaimer-info-aside"><button class="disclaimer-info-dismiss">OK</button></aside></section>'
        ].join('');
    const node = jQuery(nodeString);
    jQuery(document.body).prepend(node);
    node.on('click', 'button.disclaimer-info-dismiss', function () {
        const offset = 1000 * 60 * 60 * 24 * 365 * 10;
        const date = new Date(Date.now() + offset);
        Cookies.set(cookieName, true, { expires: date });
        node.remove();
    });
    return node;
}

(function initDisclamer() {
    const hidedisclaimer = Cookies.get(cookieName);
    if (hidedisclaimer === 'true' || hidedisclaimer === true) {
        return;
    }
    disclaimerElement(linkTarget);
})()
