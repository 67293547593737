import 'whatwg-fetch'
// import shave from 'shave';

export const localeOptionsDay = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };

export function fetchWithHeader(url) {
  return fetch(url, {
    headers: {
      'Accept': 'application/articles+json',
    }
  })
}

export function removeClass(el, className) {
    if (Array.isArray(className)) {
        for (let i = 0; i < className.length; i++) {
            removeClass(el, className[i]);
        }
        return;
    }
    if (el.classList) {
        el.classList.remove(className);
        return;
    }
    el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}


export function addClass(el, className) {
    if (Array.isArray(className)) {
        for (let i = 0; i < className.length; i++) {
            addClass(el, className[i]);
        }
        return;
    }
    if (el.classList) {
        el.classList.add(className)
    } else {
        el.className += ` ${className}`;
    }
}

export function hasClass(el, className) {
    if (Array.isArray(className)) {
        throw new Error("hasClass can not Accept a Array Variable");
    }
    if (el.classList) {
        return el.classList.contains(className)
    } else {
        return (" " + el.className + " ").replace(/[\n\t]/g, " ").indexOf(` ${className} `) > -1
    }
}

function loadError(oError) {
    throw new URIError('The script ' + oError.target.src + ' didn\'t load correctly.');
}

export function prefixScript(url, onloadFunction) {
    var newScript = document.createElement('script');
    newScript.onerror = loadError;
    if (onloadFunction) { newScript.onload = onloadFunction; }
    var currentScript = document.currentScript || (function () {
        var scripts = document.getElementsByTagName('script');
        return scripts[scripts.length - 1];
    })();
    currentScript.parentNode.insertBefore(newScript, currentScript);
    newScript.src = url;
}
export function removeNode(el) {
	if (el.parentNode === null || el.parentNode === undefined) {
        return;
    }
    el.parentNode.removeChild(el);
}