import jQuery from 'jquery';

jQuery('.c-mobil-menu .j-menu-trigger').click(function (event) {
  event.preventDefault();
  jQuery(this).closest('.c-mobil-menu').toggleClass('active');
  jQuery(this).closest('.c-mobil-menu').addClass('was-opened');
});

jQuery('.c-mobil-menu .f-menu-container .j-anchor').click(function () {
  jQuery(this).closest('.c-mobil-menu').removeClass('active');
});
