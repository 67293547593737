import '../packages/polyifill/elementClosest';
import '../packages/polyifill/customevents';

// Import JQuery Plugins
import jQuery from 'jquery';

// Import Custom JQuery Plugins
import '../packages/core/jquery/jquery.sticky'

// Import Global Custom Functions
import '../packages/core/javascript/common/cookie-info'
import '../packages/core/javascript/common/lazy-loading'

import '../packages/core/components/MobilMenu/MobilMenuBar/Menu/index'
import '../packages/core/components/Info-Buttons/index'
import CollapsController from '../packages/core/javascript/Collaps/collaps'

const menu = jQuery(".c-sticky");
if (menu.length > 0) {
  menu.sticky({ topSpacing: 0 });
}

if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
  console.log('ios')
  jQuery('html').addClass('iOS')
}

document.addEventListener("DOMContentLoaded", function (event) {
  const container = document.querySelectorAll('.j-collaps-container');
  Array.prototype.forEach.call(container, function (collaps_container) {
    const collaps = new CollapsController(collaps_container);
    collaps.init();
  });
});
