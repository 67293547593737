import { addClass, removeClass, hasClass } from "../common/common";

// $(".collaps-item .collaps-trigger").append("<div class='collaps-trigger'>hi</div>");

// $(".j-collaps__trigger").click(function(){
//   var collaps_item = $(this).closest(".j-collaps");
//   collaps_item.toggleClass("j-collaps--open");
//   collaps_item.siblings().removeClass("j-collaps--open");
//   collaps_item.siblings().removeClass("j-collaps--open");

//   if (collaps_item.hasClass("j-collaps--open")){

//   } else{
//     $('html, body').animate({
//       scrollTop: ($(collaps_item).offset().top - 50),
//     },500);
//   }

// });

class CollapsControler {

  constructor(container) {
    this.container = container;
    this.element = '.j-collaps-element';
    this.trigger = '.j-collaps-element__trigger';
    this.elementOpen = 'j-collaps--open';
  }

  hideElements() {
    Array.prototype.forEach.call(this.elements, sibling => {
      removeClass(sibling, [this.elementOpen]);
    })
  }

  init() {
    this.elements = this.container.querySelectorAll(this.element);
    this.container.addEventListener('click', event => {
      var closest = event.target.closest(this.trigger);
      if (closest === undefined || closest === null) {
        return;
      }
      if (this.container.contains(closest) === false) {
        return;
      }
      var element = event.target.closest(this.element);
      if (element === undefined || element === null) {
        return;
      }
      if (this.container.contains(element) === false) {
        return;
      }
      if (element.contains(closest)) {
        event.preventDefault();
        if (hasClass(element, this.elementOpen)) {
          this.hideElements();
        } else {
          this.hideElements();
          addClass(element, [this.elementOpen]);
        }
      }
    });
  }
}

export default CollapsControler;
