const images = document.querySelectorAll('img[data-src]');
const sources = document.querySelectorAll('source[data-srcset]');

const options = {
    rootMargin: '500px',
    threshold: .02,
};

function setSources(img) {
    const src = img.getAttribute('data-srcset')
    const curSrc = img.getAttribute('srcset')
    if (!src) return;
    img.srcset = src;
}

function preLoadSource(img) {
    const media = img.getAttribute('media');
    if (!media) return;
    const mq = window.matchMedia(media);
    if (!mq.matches) {
        mq.addListener(res => {
            if (!res.matches) return;
            setSources(img)
        });
    } else {
        setSources(img)   
    }
}

function preLoadImage(img) {
    const curSrc = img.getAttribute('src')
    const src = img.getAttribute('data-src')
    if (!src) return;
    const image = new Image();
    image.src = src;
    image.onload = () => {
        //console.info("Loaded:", src)
        img.src = src;
    }
    image.onerror = (err) => {
        if (curSrc) img.src = curSrc;
    }
}

const imageObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) {
            return;
        } else {
            preLoadImage(entry.target);
            imageObserver.unobserve(entry.target);
        }
    });
}, options);


for (let element of images) {
    imageObserver.observe(element);
}
const sourceObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) {
            return;
        } else {
            preLoadSource(entry.target);
            sourceObserver.unobserve(entry.target);
        }
    });
}, options);


for (let element of sources) {
    sourceObserver.observe(element);
}