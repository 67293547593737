import $ from "jquery";

$(".info-container").focusin(function () {
    $(this).closest(".info-container").addClass("was-active");
    $(this).closest(".info-container").addClass("active");
    $(this).closest(".info-container").siblings().removeClass("active");
});

// $(".info-container .popout").focusout(function (e) {


//     $(this).closest(".info-container").removeClass("active")
//     console.log('i') 


// });


$(document).ready(function () {
    $(".info-container .info-container__button").on('click', function (e) {
        $(this).closest(".info-container").addClass("was-active");
        $(this).closest(".info-container").toggleClass("active");
        $(this).closest(".info-container").siblings().removeClass("active");
    });
    $('.info-container .info-container__button').on('mousedown', function (event) {
        // do your magic
        event.preventDefault();
    });
});


$(document).on('click touchend', function (event) {
    if (!$(event.target).closest('.info-container').length &&
        $('.info-container').hasClass("active")) {
        $('.info-container').removeClass("active");
    }
});
